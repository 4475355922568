import {
  init,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useConfig } from '../ConfigProvider';

const Sentry: FC = () => {
  const history = useHistory();
  const { environment } = useConfig();

  useEffect(() => {
    const sentryEnvironment = environment || process.env.NODE_ENV;
    const enabled =
      ![
        'app.hector.swaarm-clients.com',
        'app.athena.swaarm-clients.com',
        'app.artemis.swaarm-clients.com',
        'app.ares.swaarm-clients.com',
        'app.mars.swaarm-clients.com',
        'app.demo.swaarm-clients.com',
        'app.buster.swaarm-clients.com',
        'app.saturn.swaarm-clients.com',
        'app.mercury.swaarm-clients.com',
        'app.venus.swaarm-clients.com',
        'app.phoenix.swaarm-clients.com',
        'app.gaia.swaarm-clients.com',
      ].includes(window.location.hostname) &&
      sentryEnvironment === 'production';

    init({
      dsn: 'https://d2c3aadcd244fcef8261c93f77fc0f8f@o195603.ingest.sentry.io/4506755875143680',
      enabled,
      environment: sentryEnvironment,
      ignoreErrors: [
        'ResizeObserver loop',
        'useRequest has caught the exception',
      ],
      integrations: [
        reactRouterV5BrowserTracingIntegration({
          history,
        }),
        replayIntegration(),
      ],
      normalizeDepth: 10,
      release: `search-${VERSION}`,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // This sets the sample rate to be 1%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.01,
      tracesSampleRate: 0.1,
    });
  }, [environment, history]);

  return null;
};

Sentry.displayName = 'Sentry';

export default Sentry;
